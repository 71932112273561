<template>
  <!--  <NuxtLoadingIndicator color="#00bfa5" :height="6" /> -->
  <div>
    <TemplateHeader />
    <TemplateSidebar />
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <slot />
      </div>
    </div>
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <button class="btn btn-primary btn-icon scroll-top" type="button">
      <i data-feather="arrow-up"></i>
    </button>
  </div>
</template>
<script setup>
import { useHead } from "@vueuse/head";

useHead({
  bodyAttrs: {
    "data-open": "click",
    "data-menu": "vertical-menu-modern",
    "data-col": "",
    class: "vertical-layout vertical-menu-modern navbar-floating footer-static",
  },
});

import { onMounted } from "vue";

onMounted(() => {
  feather.replace();
});
</script>
